import { TrackingApi } from 'api/TrackingApi';
import { VisitsApi } from 'api/VisitsApi';
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from 'helpers/sessionStorage';
import { useRegistrationAnalytics } from 'hooks/analytics/useRegistrationAnalytics';
import { useSystemSearchQueries } from 'hooks/useSystemSearchQueries';

export const TRACK_START_APP_SESSION_STORAGE_KEY = 'TRACK_START_APP';
export const TRANSLATIONS_BUILD_VERSION_SESSION_STORAGE_KEY =
  'TRANSLATIONS_BUILD_VERSION';
export const AUTOLOGIN_TOKEN_NOT_FOUND_ERROR_MESSAGE = 'Token not found';

export const useSessionStartTrack = () => {
  const { getAutoLoginQuery } = useSystemSearchQueries();
  const { trackRegistrationSuccess } = useRegistrationAnalytics();

  const trackStartSession = async (isSaveEvent?: boolean) => {
    const { value: autologinToken } = getAutoLoginQuery();
    const isAlreadyTracked = getSessionStorageItem(
      TRACK_START_APP_SESSION_STORAGE_KEY
    );
    let translationsBuildVersion = '';

    if (isAlreadyTracked) {
      try {
        const { data } = await VisitsApi.getTranslationBuildId();

        if (data.trans_build_id) {
          return data.trans_build_id;
        }
      } catch (err) {
        console.error(err);
      }

      return translationsBuildVersion;
    }

    if (autologinToken) {
      try {
        const { trans_build_id: buildVersion } = await TrackingApi.track({
          autologinToken,
        });

        translationsBuildVersion = String(buildVersion);

        trackRegistrationSuccess();
      } catch (err: any) {
        console.error(err);
      }
    } else {
      try {
        const { trans_build_id: buildVersion } = await TrackingApi.track();

        translationsBuildVersion = String(buildVersion);
      } catch (err) {
        console.error(err);
      }
    }

    if (isSaveEvent) {
      setSessionStorageItem(TRACK_START_APP_SESSION_STORAGE_KEY, '1');
    }

    return translationsBuildVersion;
  };

  return {
    trackStartSession,
  };
};
