export const setLocalStorageItem = (key: string, value: any) => {
  try {
    if (!window?.localStorage) return null;

    return localStorage?.setItem(key, value);
  } catch (error) {
    return null;
  }
};

export const getLocalStorageItem = (key: string) => {
  try {
    if (!window?.localStorage) return null;

    return localStorage?.getItem(key);
  } catch (error) {
    return null;
  }
};

export const removeLocalStorageItem = (key: string) => {
  try {
    if (!window?.localStorage) return null;

    return localStorage?.removeItem(key);
  } catch (error) {
    return null;
  }
};
