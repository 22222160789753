import React from 'react';
import classNames from 'classnames';

import { Spinner, SpinnerTypes } from 'components/base/Spinner';

import css from './fullscreenSpinner.module.sass';

export const FullscreenSpinner: React.FC = () => {
  return (
    <div className={classNames(css.root)}>
      <Spinner type={SpinnerTypes.Dark} size="36px" />
    </div>
  );
};
