import React from 'react';
import ReactDOM from 'react-dom';

import { MirrorService } from 'services/MirrorService';

import { setXPrevUrl, setXRefererUrl } from 'api/httpClient';
import { TrackingApi } from 'api/TrackingApi';
import { setTrackingCookiesFromUrl } from 'helpers/analytics/trackingCookies';

import { RootApp } from './RootApp';

import 'context-filter-polyfill';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'styles/main.sass';

MirrorService.init();

/**
 * Adds x-prev-url header to every request. Need for BE
 */
setXPrevUrl(document.referrer);
setXRefererUrl(window.location.href);

/**
 * location.search part of url stored in cookies
 * needed for BE and to get marketingParams from it
 */
setTrackingCookiesFromUrl();

TrackingApi.trackReactChunkLoadEnd();

ReactDOM.render(<RootApp />, document.getElementById('root'));

// @ts-ignore
if (process.env.NODE_ENV === 'development' && module.hot) {
  // @ts-ignore
  module.hot.accept('./RootApp', render);
}
