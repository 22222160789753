import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { assertString } from 'helpers/utils/checkType';

export enum QueryKeys {
  LoginToken = 'login_token',
  AutoRegister = 'ar',
  Popunder = 'pu',
  InitialId = 'initial_id',
  PartnerId = 'partner_id',
  PartnerLoginId = 'partner_login_id',
  DestinationPath = 'dp',
  ApiBuyingDestination = 'destination',
  TargetPath = 'target',
  UserId = 'user_id',
  WSToken = 'ws_token',
  BillingToken = 'billing_token',
  Delay = 'delay',
  LpStep = 'lp_step',
  BackButton = 'bb',
  ReqCid = 'req_cid',
  MF = 'mf',
  UserData = 'user_data',
  EventDataId = 'event_data_id',
  ClickId = 'click_id',
  Funnel = 'funnel',
  UserRedirect = 'ur',
  Fbclid = 'fbclid',
  ApiDyn = 'api_dyn',
  ApiReg = 'api_reg',
  PopunderTrack = 'pu_track',
  BundlePackageId = 'bundle_package_id',
  ExtEmail = 'ext',
  Quiz = 'quiz',
  SuccessPayment = 'success_payment',
  StickerPackId = 'sticker_pack_id',
  PushRetry = 'push_retry',
  DestUrl = 'dest_url',
  UpgradeOfferStatus = 'upgrade_offer_status',
  OnePackageActive = 'one_package_active',
  ConfirmMail = 'confirm_mail',
}

export const useSystemSearchQueries = () => {
  const { search } = useLocation();

  const {
    [QueryKeys.LoginToken]: loginToken,
    [QueryKeys.AutoRegister]: autoRegister,
    [QueryKeys.Popunder]: popunder,
    [QueryKeys.InitialId]: initialId,
    [QueryKeys.PartnerLoginId]: partnerLoginId,
    [QueryKeys.PartnerId]: partnerId,
    [QueryKeys.DestinationPath]: destinationPath,
    [QueryKeys.ApiBuyingDestination]: apiBuyingDestination,
    [QueryKeys.TargetPath]: targetPath,
    [QueryKeys.UserId]: userId,
    [QueryKeys.WSToken]: wsToken,
    [QueryKeys.BillingToken]: billingToken,
    [QueryKeys.Delay]: delay,
    [QueryKeys.LpStep]: lpStep,
    [QueryKeys.BackButton]: bb,
    [QueryKeys.ReqCid]: reqCid,
    [QueryKeys.MF]: mf,
    [QueryKeys.UserData]: userData,
    [QueryKeys.EventDataId]: eventDataId,
    [QueryKeys.ClickId]: clickId,
    [QueryKeys.Funnel]: funnel,
    [QueryKeys.UserRedirect]: ur,
    [QueryKeys.Fbclid]: fbclid,
    [QueryKeys.ApiDyn]: apiDyn,
    [QueryKeys.ApiReg]: apiReg,
    [QueryKeys.PopunderTrack]: puTrack,
    [QueryKeys.BundlePackageId]: bundlePackageId,
    [QueryKeys.ExtEmail]: extEmail,
    [QueryKeys.Quiz]: quiz,
    [QueryKeys.SuccessPayment]: successPayment,
    [QueryKeys.StickerPackId]: stickerPackId,
    [QueryKeys.PushRetry]: pushRetry,
    [QueryKeys.UpgradeOfferStatus]: upgradeOfferStatus,
    [QueryKeys.ConfirmMail]: confirmMail,
  } = queryString.parse(search);

  const getUpgradeOfferStatusQuery = useCallback(() => {
    return {
      key: QueryKeys.UpgradeOfferStatus,
      value: assertString(upgradeOfferStatus) ? upgradeOfferStatus : null,
    };
  }, [upgradeOfferStatus]);

  const getAutoLoginQuery = useCallback(() => {
    return {
      key: QueryKeys.LoginToken,
      value: assertString(loginToken) ? loginToken : null,
    };
  }, [loginToken]);

  const getAutoRegisterQuery = useCallback(() => {
    return {
      key: QueryKeys.AutoRegister,
      value: assertString(autoRegister) ? autoRegister : null,
    };
  }, [autoRegister]);

  const getPopunderQuery = useCallback(() => {
    return {
      key: QueryKeys.Popunder,
      value: assertString(popunder) ? popunder : null,
    };
  }, [popunder]);

  const getInitialIdQuery = useCallback(() => {
    return {
      key: QueryKeys.InitialId,
      value: assertString(initialId) ? initialId : null,
    };
  }, [initialId]);

  const getPartnerIdQuery = useCallback(() => {
    return {
      key: QueryKeys.PartnerId,
      value: assertString(partnerId) ? partnerId : null,
    };
  }, [partnerId]);

  const getPartnerLoginIdQuery = useCallback(() => {
    return {
      key: QueryKeys.PartnerLoginId,
      value: assertString(partnerLoginId) ? partnerLoginId : null,
    };
  }, [partnerLoginId]);

  const getDestinationPathQuery = useCallback(() => {
    return {
      key: QueryKeys.DestinationPath,
      value: assertString(destinationPath) ? destinationPath : null,
    };
  }, [destinationPath]);

  const getApiBuyingDestinationQuery = useCallback(() => {
    return {
      key: QueryKeys.ApiBuyingDestination,
      value: assertString(apiBuyingDestination) ? apiBuyingDestination : null,
    };
  }, [apiBuyingDestination]);

  const getTargetPathQuery = useCallback(() => {
    return {
      key: QueryKeys.TargetPath,
      value: assertString(targetPath) ? targetPath : null,
    };
  }, [targetPath]);

  const getUserIdQuery = useCallback(() => {
    return {
      key: QueryKeys.UserId,
      value: assertString(userId) ? userId : null,
    };
  }, [userId]);

  const getWSTokenQuery = useCallback(() => {
    return {
      key: QueryKeys.WSToken,
      value: assertString(wsToken) ? wsToken : null,
    };
  }, [wsToken]);

  const getBillingTokenQuery = useCallback(() => {
    return {
      key: QueryKeys.BillingToken,
      value: assertString(billingToken) ? billingToken : null,
    };
  }, [billingToken]);

  const getDelayQuery = useCallback(() => {
    return {
      key: QueryKeys.Delay,
      value: assertString(delay) ? delay : null,
    };
  }, [delay]);

  const getLpStepQuery = useCallback(() => {
    return {
      key: QueryKeys.LpStep,
      value: assertString(lpStep) ? lpStep : null,
    };
  }, [lpStep]);

  const getBackButtonQuery = useCallback(() => {
    return {
      key: QueryKeys.BackButton,
      value: assertString(bb) ? bb : null,
    };
  }, [bb]);

  const getReqCidQuery = useCallback(() => {
    return {
      key: QueryKeys.ReqCid,
      value: assertString(reqCid) ? reqCid : null,
    };
  }, [reqCid]);

  const getMFQuery = useCallback(() => {
    return {
      key: QueryKeys.MF,
      value: assertString(mf) ? mf : null,
    };
  }, [mf]);

  const getUserDataQuery = useCallback(() => {
    return {
      key: QueryKeys.UserData,
      value: assertString(userData) ? userData : null,
    };
  }, [userData]);

  const getEventDataIdQuery = useCallback(() => {
    return {
      key: QueryKeys.EventDataId,
      value: assertString(eventDataId) ? eventDataId : null,
    };
  }, [eventDataId]);

  const getClickIdQuery = useCallback(() => {
    return {
      key: QueryKeys.ClickId,
      value: assertString(clickId) ? clickId : null,
    };
  }, [clickId]);

  const getFunnelQuery = useCallback(() => {
    return {
      key: QueryKeys.Funnel,
      value: assertString(funnel) ? funnel : null,
    };
  }, [funnel]);
  const getUserRedirectQuery = useCallback(() => {
    return {
      key: QueryKeys.UserRedirect,
      value: assertString(ur) ? ur : null,
    };
  }, [ur]);

  const getFbclidQuery = useCallback(() => {
    return {
      key: QueryKeys.Fbclid,
      value: assertString(fbclid) ? fbclid : null,
    };
  }, [fbclid]);

  const getApiDynQuery = useCallback(() => {
    return {
      key: QueryKeys.ApiDyn,
      value: assertString(apiDyn) ? apiDyn : null,
    };
  }, [apiDyn]);

  const getApiRegQuery = useCallback(() => {
    return {
      key: QueryKeys.ApiReg,
      value: assertString(apiReg) ? apiReg : null,
    };
  }, [apiReg]);

  const getPopunderTrackQuery = useCallback(() => {
    return {
      key: QueryKeys.PopunderTrack,
      value: assertString(puTrack) ? puTrack : null,
    };
  }, [puTrack]);

  const getBundlePackageIdQuery = useCallback(() => {
    return {
      key: QueryKeys.BundlePackageId,
      value: assertString(bundlePackageId) ? bundlePackageId : null,
    };
  }, [bundlePackageId]);

  const getExtEmailQuery = useCallback(() => {
    return {
      key: QueryKeys.ExtEmail,
      value: assertString(extEmail) ? extEmail : null,
    };
  }, [extEmail]);

  const getQuizQuery = useCallback(() => {
    return {
      key: QueryKeys.Quiz,
      value: assertString(quiz) ? quiz : null,
    };
  }, [quiz]);

  const getSuccessPaymentQuery = useCallback(() => {
    return {
      key: QueryKeys.SuccessPayment,
      value: assertString(successPayment) ? successPayment : null,
    };
  }, [successPayment]);

  const getStickerPackIdQuery = useCallback(() => {
    return {
      key: QueryKeys.StickerPackId,
      value: assertString(stickerPackId) ? stickerPackId : null,
    };
  }, [stickerPackId]);
  const getPushRetryQuery = useCallback(() => {
    return {
      key: QueryKeys.PushRetry,
      value: assertString(pushRetry) ? pushRetry : null,
    };
  }, [pushRetry]);

  const getConfirmMailQuery = useCallback(() => {
    return {
      key: QueryKeys.ConfirmMail,
      value: assertString(confirmMail) ? confirmMail : null,
    };
  }, [confirmMail]);

  return {
    getAutoLoginQuery,
    getAutoRegisterQuery,
    getPopunderQuery,
    getInitialIdQuery,
    getPartnerIdQuery,
    getPartnerLoginIdQuery,
    getDestinationPathQuery,
    getApiBuyingDestinationQuery,
    getTargetPathQuery,
    getUserIdQuery,
    getWSTokenQuery,
    getBillingTokenQuery,
    getDelayQuery,
    getLpStepQuery,
    getBackButtonQuery,
    getReqCidQuery,
    getMFQuery,
    getUserDataQuery,
    getUpgradeOfferStatusQuery,
    getEventDataIdQuery,
    getClickIdQuery,
    getFunnelQuery,
    getUserRedirectQuery,
    getFbclidQuery,
    getApiDynQuery,
    getApiRegQuery,
    getPopunderTrackQuery,
    getBundlePackageIdQuery,
    getExtEmailQuery,
    getQuizQuery,
    getSuccessPaymentQuery,
    getStickerPackIdQuery,
    getPushRetryQuery,
    getConfirmMailQuery,
  };
};
