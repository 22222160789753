import cookie from 'js-cookie';

import { ProjectCookieSettingsTypes } from 'types/interfaces/CookiesSettings';

import {
  COOKIES_SETTINGS_SHOWN_KEY,
  PROJECT_COOKIES_SETTINGS_KEY,
} from 'helpers/constants';

import { getCurrentDomainWithoutSubdomain } from './env';
import { getLocalStorageItem, setLocalStorageItem } from './localStorage';

const PERFORMANCE_COOKIES: string[] = ['INTRO_SKIP_FUNNEL', 'TPTheme', 'found'];
const ADVERTISING_COOKIES: string[] = [
  'tracking_params',
  'tracking_referer',
  'partner_id',
];

export const removeCookieByRegExp = (
  expressions: RegExp[],
  cookieKey: string
) => {
  expressions.forEach((item) => {
    if (item.test(cookieKey)) {
      cookie.remove(cookieKey);
    }
  });
};

export const removeCookieByEquals = (
  expressions: string[],
  cookieKey: string
) => {
  const currentDomain = getCurrentDomainWithoutSubdomain();

  expressions.forEach((item) => {
    if (item === cookieKey) {
      cookie.remove(cookieKey, { domain: window.location.hostname });
      cookie.remove(cookieKey, { domain: `.${currentDomain}` });
      cookie.remove(cookieKey);
    }
  });
};

export const getDefaultProjectCookiesSettings = (
  defaultValue: boolean
): ProjectCookieSettingsTypes => ({
  isEnabledPerformanceCookies: defaultValue,
  isEnabledAdvertisingCookies: defaultValue,
});

export const transformSelectedValuesIntoString = (
  values: ProjectCookieSettingsTypes
) =>
  JSON.stringify(
    Object.entries(values)
      .filter(([, value]) => !!value)
      .map(([key]) => key)
  );

export const getIsCookieBannerAlreadyShown = () =>
  getLocalStorageItem(COOKIES_SETTINGS_SHOWN_KEY);

export const getProjectCookieSettings = (): ProjectCookieSettingsTypes => {
  const projectCookiesSettings = getLocalStorageItem(
    PROJECT_COOKIES_SETTINGS_KEY
  );

  if (!projectCookiesSettings) {
    return getDefaultProjectCookiesSettings(true);
  }

  return JSON.parse(projectCookiesSettings) as ProjectCookieSettingsTypes;
};

export const applyCookies = () => {
  const cookiesSettings = getProjectCookieSettings();
  setLocalStorageItem(
    PROJECT_COOKIES_SETTINGS_KEY,
    JSON.stringify(cookiesSettings)
  );

  const cookiesList = Object.entries(cookie.get());
  cookiesList.forEach(([cookieKey]) => {
    if (cookiesSettings.isEnabledPerformanceCookies === false) {
      removeCookieByEquals(PERFORMANCE_COOKIES, cookieKey);
    }
    if (cookiesSettings.isEnabledAdvertisingCookies === false) {
      removeCookieByEquals(ADVERTISING_COOKIES, cookieKey);
    }
  });
};

export const rejectCookies = () => {
  const cookiesSettings = getDefaultProjectCookiesSettings(false);
  setLocalStorageItem(
    PROJECT_COOKIES_SETTINGS_KEY,
    JSON.stringify(cookiesSettings)
  );

  const cookiesList = Object.entries(cookie.get());
  cookiesList.forEach(([cookieKey]) => {
    if (cookiesSettings.isEnabledPerformanceCookies === false) {
      removeCookieByEquals(PERFORMANCE_COOKIES, cookieKey);
    }
    if (cookiesSettings.isEnabledAdvertisingCookies === false) {
      removeCookieByEquals(ADVERTISING_COOKIES, cookieKey);
    }
  });
};

export const getIsDisabledPerformanceCookies = () =>
  getProjectCookieSettings().isEnabledPerformanceCookies === false &&
  getIsCookieBannerAlreadyShown();

export const getIsDisabledAdvertisingCookies = () =>
  getProjectCookieSettings().isEnabledAdvertisingCookies === false &&
  getIsCookieBannerAlreadyShown();
