export const setSessionStorageItem = (key: string, value: any) => {
  try {
    if (!window?.sessionStorage) return null;

    return sessionStorage?.setItem(key, value);
  } catch (error) {
    return null;
  }
};

export const getSessionStorageItem = (key: string) => {
  try {
    if (!window?.sessionStorage) return null;

    return sessionStorage?.getItem(key);
  } catch (error) {
    return null;
  }
};

export const removeSessionStorageItem = (key: string) => {
  try {
    if (!window?.sessionStorage) return null;

    return sessionStorage?.removeItem(key);
  } catch (error) {
    return null;
  }
};
