import { MirrorService } from 'services/MirrorService';

import { TrackingApi } from 'api/TrackingApi';

enum GtmEventName {
  PushSubscribeSuccesss = 'pushSubscribeSuccess',
  UserReg = 'userREG',
  CardSubmit = 'submit',
  TrialSuccess = 'trial_success',
  TrialSoft = 'trial_soft',
  PurchaseSuccess = 'purchase_success',
  PurchaseSoft = 'purchase_soft',
  TrialHard = 'trial_hard',
  PurchaseHard = 'purchase_hard',
  UserId = 'userId',
}

export const Gtm = {
  init(params: { gtmId: string }) {
    const { gtmId } = params;

    if (!gtmId) {
      return;
    }

    const innerScript = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    const noscriptInner = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    const script = document.createElement('script');
    const noscript = document.createElement('noscript');

    document.head.insertBefore(script, document.head.childNodes[0]);
    document.body.insertBefore(noscript, document.body.childNodes[0]);

    script.innerHTML = innerScript;
    noscript.innerHTML = noscriptInner;
  },

  get isShouldSendToVerticaEvent() {
    return (
      MirrorService.isBestHookuper ||
      MirrorService.isDatesMe ||
      MirrorService.isSimpleDates
    );
  },

  sendEvent(obj: object) {
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push(obj);
  },

  userRegEvent() {
    this.sendEvent({ event: GtmEventName.UserReg });

    // TODO refactor this
    if (this.isShouldSendToVerticaEvent) {
      TrackingApi.trackGtmPostback({
        info: MirrorService.isSimpleDates ? 'facebook_reg' : 'gtm_reg',
      });
    }
  },

  trialSuccessEvent() {
    this.sendEvent({ event: GtmEventName.TrialSuccess });
  },

  purchaseSuccessEvent() {
    this.sendEvent({ event: GtmEventName.PurchaseSuccess });
  },

  cardSubmit() {
    this.sendEvent({ event: GtmEventName.CardSubmit });
  },

  userIdEvent(id: number) {
    this.sendEvent({ event: GtmEventName.UserId, user_id: id });
  },
};
