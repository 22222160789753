import cookie from 'js-cookie';
import queryString from 'query-string';

import { MirrorService } from 'services/MirrorService';
import { AppDomain } from 'types/enums/AppDomain';

import { getCurrentDomainWithoutSubdomain } from 'helpers/env';
import { getLocalStorageItem, setLocalStorageItem } from 'helpers/localStorage';

import { getIsDisabledAdvertisingCookies } from '../cookiesSettings';

const TRACKING_PARAMS_COOKIE_NAME = 'tracking_params';
const TRACKING_REFERRER_COOKIE_NAME = 'tracking_referer';
const CLICK_ID_QUERY_NAME = 'click_id';
const PARTNER_ID_QUERY_NAME = 'partner_id';
const EXPIRE_TIME_IN_DAYS = 30;

export const setTrackingCookiesFromUrl = () => {
  const {
    referrer,
    location: { search, hostname },
  } = document;

  const {
    [CLICK_ID_QUERY_NAME]: clickId,
    [PARTNER_ID_QUERY_NAME]: partnerId,
    fbclid,
  } = queryString.parse(search);

  const fromExternalDomain = (url: string) => !url.includes(hostname);
  const currentDomain = getCurrentDomainWithoutSubdomain();

  if (partnerId && !getIsDisabledAdvertisingCookies()) {
    let finalSearch = search;

    // * this hack needed for mainstream analytics matching
    if (MirrorService.isSimpleDates && fbclid && !clickId) {
      finalSearch += `&${CLICK_ID_QUERY_NAME}=${fbclid}`;
    }

    if (currentDomain !== AppDomain.Hookupers) {
      cookie.set(TRACKING_PARAMS_COOKIE_NAME, finalSearch, {
        expires: EXPIRE_TIME_IN_DAYS,
        domain: `.${currentDomain}`,
      });
    }

    cookie.set(TRACKING_PARAMS_COOKIE_NAME, finalSearch, {
      expires: EXPIRE_TIME_IN_DAYS,
      domain: `.${AppDomain.Hookupers}`,
    });
  }

  if (referrer && fromExternalDomain(referrer)) {
    cookie.set(TRACKING_REFERRER_COOKIE_NAME, referrer, {
      expires: EXPIRE_TIME_IN_DAYS,
      domain: `.${AppDomain.Hookupers}`,
    });
  }
};

export const setTrackingCookies = (trackingCookies: string) => {
  if (getIsDisabledAdvertisingCookies()) {
    setLocalStorageItem(TRACKING_PARAMS_COOKIE_NAME, trackingCookies);

    return;
  }

  const domain = getCurrentDomainWithoutSubdomain();

  cookie.set(TRACKING_PARAMS_COOKIE_NAME, trackingCookies, {
    expires: EXPIRE_TIME_IN_DAYS,
    domain: `.${domain}`,
  });
};

export const getTrackingCookie = () => {
  return (
    cookie.get(TRACKING_PARAMS_COOKIE_NAME) ||
    getLocalStorageItem(TRACKING_PARAMS_COOKIE_NAME) ||
    ''
  );
};
