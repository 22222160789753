import TiktokPixel from 'tiktok-pixel';

import { MirrorService } from 'services/MirrorService';

import { Gtm } from 'helpers/analytics/gtm';
import { getUserIdFromCookies } from 'helpers/userId';

export const useRegistrationAnalytics = () => {
  const trackRegistrationSuccess = () => {
    Gtm.userIdEvent(getUserIdFromCookies());
    Gtm.userRegEvent();

    if (
      MirrorService.isDatesMe &&
      MirrorService.tiktokId &&
      MirrorService.isProd
    ) {
      TiktokPixel.track('CompleteRegistration', {});
    }
  };

  return {
    trackRegistrationSuccess,
  };
};
