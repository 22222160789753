import React, { Suspense } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';

import { MirrorService } from 'services/MirrorService';

import { FullscreenSpinner } from 'components/shared/FullscreenSpinner';

const TDSApp = loadable(
  () => import(/* webpackChunkName: "TDSApp" */ './TDSApp'),
  {
    resolveComponent: (component) => component.TDSApp,
    fallback: <FullscreenSpinner />,
  }
);

const SiteApp = loadable(
  () => import(/* webpackChunkName: "SiteApp" */ './SiteAppContainer'),

  {
    resolveComponent: (component) => component.SiteAppContainer,
    fallback: <FullscreenSpinner />,
  }
);

const DescriptorApp = loadable(
  () =>
    import(/* webpackChunkName: "DescriptorApp" */ './DescriptorAppContainer'),
  {
    resolveComponent: (component) => component.DescriptorAppContainer,
  }
);

const appleTouchIconUrl = `/favicons/${MirrorService.siteName}-192x192.png`;

export const RootApp = () => {
  return (
    <React.StrictMode>
      <HelmetProvider>
        <Helmet>
          {MirrorService.isPpls && (
            <meta
              name="google-site-verification"
              content="k6VgPJTdu4EbyVO-8A0vJC5ky40wDa4ROLR-rKsfUhQ"
            />
          )}
          {MirrorService.isDatesMe ? (
            <>
              <meta
                name="description"
                content="Social discovery platform where you can chat and have fun discussions with interesting singles worldwide. It's super easy to meet new people and make connections now!"
              />
              <meta
                name="keywords"
                content="Communication, chat, social, connect, talk, exchange, dialogue, simple, easy, hub, spot, corner, realm, square, friendly, sociable, interact, discuss, network, zone, haven, open, plain, basic, user-friendly, accessible, welcoming, plain-speaking, converse, uncomplicated, hassle-free, down-to-earth, effortless, smooth, comfortable, interactive, communal, share, community, clear, direct, uncomplicated, direct, accessible, minimalistic, open, direct"
              />
            </>
          ) : (
            <>
              <meta
                name="description"
                content="Online dating service to find and meet local singles in the UK, US, CA, AU. Explore chats, finder feature, video - everything to help you find your love"
              />
              <meta
                name="keywords"
                content="Personals, best hookuper, Besthookuper, Besthookuper login, Besthookuper sing up, Desktop Besthookuper, Mobile Besthookuper, Tablet Besthookuper, Dating, Online Dating, Sex Dating, Dating Site, Singles, Singles Dating, Senior Dating, Sex, Local Dating, Adult Finder, Adult Match, Online Match, Sex Hook up sites , Sex Hook up, Sex Hook up sites UK, Sex Hook up sites US, Sex Hook up sites CA, Sex Hook up sites Au, Hook ups, Hookupers, Casual Sex Dating, Casual Sex Near Me, Casual Sex finder, Casual Sex Dating Sites, Sex Chat, Sex Chat Sites, Local Sex Personals, Local Sex, Adult Mobile Dating, Adult Desktop Dating, Women looking for sex, Hot sex, Hot Sex Sites, Sex with Milf, Adult dating service, Sexfinder, Find sex, Adult Sex Meet, Adult Site"
              />
            </>
          )}
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={appleTouchIconUrl}
          />
          <link rel="apple-touch-icon" sizes="57x57" href={appleTouchIconUrl} />
          <link
            rel="apple-touch-icon"
            sizes="114x114"
            href={appleTouchIconUrl}
          />
          <link rel="apple-touch-icon" sizes="72x72" href={appleTouchIconUrl} />
          <link
            rel="apple-touch-icon"
            sizes="144x144"
            href={appleTouchIconUrl}
          />
          <link rel="apple-touch-icon" sizes="60x60" href={appleTouchIconUrl} />
          <link
            rel="apple-touch-icon"
            sizes="120x120"
            href={appleTouchIconUrl}
          />
          <link rel="apple-touch-icon" sizes="76x76" href={appleTouchIconUrl} />
          <link
            rel="apple-touch-icon"
            sizes="152x152"
            href={appleTouchIconUrl}
          />
          <link rel="apple-touch-icon" href={appleTouchIconUrl} />
        </Helmet>
        <BrowserRouter>
          <Suspense fallback={<FullscreenSpinner />}>
            {!MirrorService.isDescriptorGroupId && (
              <Switch>
                <Route path="/entry" component={TDSApp} />
                <Route path="*" component={SiteApp} />
              </Switch>
            )}
            {MirrorService.isDescriptorGroupId && (
              <Switch>
                <Route path="*" component={DescriptorApp} />
              </Switch>
            )}
          </Suspense>
        </BrowserRouter>
      </HelmetProvider>
    </React.StrictMode>
  );
};
