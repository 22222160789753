import { UserCard } from 'types/interfaces/user/UserCard';

import { httpClient } from './httpClient';

export interface VisitsListResponse {
  data: {
    is_new: boolean;
    profile: UserCard;
  }[];
  next: string | null;
}

export interface MyVisitsPopupResponse {
  visits: UserCard[];
}

export const VisitsApi = {
  async fetchVisitsList(url?: string) {
    const { data } = await httpClient.get<VisitsListResponse>(url ?? '/visits');

    return data;
  },

  async fetchMyVisitsPopup() {
    const { data } = await httpClient.get<MyVisitsPopupResponse>(
      '/visits/popup'
    );

    return data;
  },
  getTranslationBuildId() {
    return httpClient.get('/visit');
  },
};
