import { SelectOption } from 'types';
import { UserPhoto } from 'types/interfaces/user/UserPhoto';
import { UserVideo } from 'types/interfaces/user/UserVideo';

import { CreditsInsufficientError } from 'api/CreditApi';
import { PaymentFormIntentResponse, PaymentFormResponse } from 'api/PaymentApi';

export const hasProperty = (obj: object, prop: string | number | symbol) =>
  Object.prototype.hasOwnProperty.call(obj, prop);

export const assertSelectOption = (prop: unknown): prop is SelectOption =>
  Object.prototype.hasOwnProperty.call(prop, 'value') &&
  Object.prototype.hasOwnProperty.call(prop, 'label');

export const assertSelectOptionArray = (
  prop: unknown
): prop is SelectOption[] =>
  Array.isArray(prop) && prop.every(assertSelectOption);

export const assertNotNullObject = (prop: unknown): prop is object =>
  typeof prop === 'object' && prop !== null;

export const assertString = (prop: unknown): prop is string =>
  typeof prop === 'string';

export const assertStringArray = (prop: unknown): prop is string[] =>
  Array.isArray(prop) && prop.every(assertString);

export const assertNumber = (prop: unknown): prop is number => {
  if (typeof prop !== 'number') {
    return false;
  }

  if (prop !== Number(prop)) {
    return false;
  }

  if (prop === Infinity) {
    return false;
  }

  return true;
};

export const assertNumberArray = (prop: unknown): prop is number[] => {
  return Array.isArray(prop) && prop.every(assertNumber);
};

export const assertUserPhotoMedia = (
  file: UserPhoto | UserVideo
): file is UserPhoto => {
  return (file as UserPhoto).profile_url !== undefined;
};

export const assertCreditsInsufficientError = (
  error: any
): error is CreditsInsufficientError => {
  return error?.response?.data?.code === 'insufficient_funds';
};

export const assertIntentSDKFormData = (
  data: PaymentFormResponse | PaymentFormIntentResponse
): data is PaymentFormIntentResponse => {
  return Boolean((data as PaymentFormIntentResponse).merchant_data);
};
